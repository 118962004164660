import React from 'react'

import { smartypants } from '../../utils/smartypants'
import { H3 } from '../styled/H3'
import { PageLink, PageLinkShadowless } from '../styled/PageLink'
import { Article } from './ItemEntry'

export function CategoryItem({ item: category }) {
  const title = category.title || category.slug.current

  return (
    <Article>
      <H3>
        <PageLinkShadowless to={category.slug.current}>
          {smartypants(title)}
        </PageLinkShadowless>
      </H3>
      <p>{category.description}</p>
      <PageLink to={category.slug.current}>See all posts</PageLink>
    </Article>
  )
}
