import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { ItemsList } from '../components/page/ItemsList'
import { CategoryItem } from '../components/page/CategoryItem'
import { H2 } from '../components/styled/H2'
import { PreviousNextPagination } from '../components/generic/PreviousNext'

function Categories({
  data: {
    allCategories: { edges: categories },
  },
  pageContext: { currentPage, numPages },
}) {
  return (
    <Layout>
      <SEO
        title="All Categories"
        currentPage={currentPage}
        numPages={numPages}
        image={`/og-images/categories/categories-page-${currentPage}.png`}
      />
      <Bio />
      <ItemsList
        header={<H2>All Categories</H2>}
        items={categories}
        component={CategoryItem}
      />
      <PreviousNextPagination
        currentPage={currentPage}
        numPages={numPages}
        linkPrefix="/categories/"
      />
    </Layout>
  )
}

export default Categories

export const pageQuery = graphql`
  query CategoriesQuery($skip: Int!, $limit: Int!) {
    allCategories: allSanityCategory(
      sort: { fields: [title], order: ASC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          description
        }
      }
    }
  }
`
